import "./PGP.scss";

const PGP_PUB = `-----BEGIN PGP PUBLIC KEY BLOCK-----

mQGNBGHzHEEBDACyxqMpYwSH6vSxPRxCd6McRm76kpcI1Cuw8Rm6BG4kB8Pn73c8
Ipncuc/AzyVq/coHkVjVNx5HQrF2X1L2rvKEcvZQm/HOSNcO6SSv3qgDYYpjQDFb
vtEPB8apPlOOeQynqo9drXPfL3uvZP+EjOZrv5rrZi/+CsrjbU1C9GoShx4WSsT1
z1oAGstKm30aTo6EkdzvPWRnInqU22u6AHpzzvZdnsixvSO5ObzWMlwGiS37Fi54
HSR8bPr6sTT8a+n8U1dp3qZVnGTI90z5z0cU63H9YS+wOz6bJ8x1TYxfv04oR8ZB
ISRrdziHht7J0Cj6ZQAJWU1OPWLemhKgcMS8K3/2BDpU+iAzUB6iVnyX//IWzPfT
zW9xYCwSHfxE6rr4MlzhTZ2aG8hgvFLCxQkW+izaAOdWui/6SFhKACUDQGdCfzEV
xC7IPE4k69yHf2i1Amkms7d+0sQohbqU/BbHFs5prnhGyxGNrbH5ypHtrRtKiZjk
28pZrwwWAwldY50AEQEAAbQ/Qm/En2F6acOnaSBLYXl5dW0gVGFyaWjDp2VzaSA8
aW5mb0Bib2dhemljaWtheXl1bXRhcmloY2VzaS5jb20+iQHOBBMBCAA4FiEEJ0Od
7jYJ3x49GfVFFd82EBoRtzYFAmHzHEECGwMFCwkIBwIGFQoJCAsCBBYCAwECHgEC
F4AACgkQFd82EBoRtzYVuQv/QZro+IrgFJKHAI9zrwCbYtpZyJNfWTI8k4NfDWCG
T0iesTMrXmqGtlKpxLzwPaeVCtihRFUGGz7HyfUcMxV0L6juW1I2KJXCCq6+GtFl
9a1m6p9RFdu7JRCVWyjOn61dTjncFwR/VxKH5APBiEtZQq0H4t3+IAiaCo/tQ7Lf
PUJC0f7JUjcsyclxvjaSGSYLuIWYqz31rITEXLEWPSgIslYlXj0UXRtRItsKIU+9
qWopbcYJ5mKlrcd+M51hF88Jwu5iBtQJWD2pQuJcwHqL2GRMk2du1jDPQCDZyiT+
CdVq691eFlJoGaI7Di5e/FYKnfGTiAQugNQoVmj3S4zpOBvufO067c+d4abObSs3
0sfHgyRlQBV2Yb+ZOOJmIkWhQppaDrX09vTAl2LZp41+UlvJRUC4eK4kdz+QER/Z
lCkEJQ0vjpzVLJ7nr/lzFhf78O/mSdEVbPDu/U+y7IiH+RwfJNEHRM8jhK8hJ1yy
iz1Ki5SPMHp1x/+zaT6ubU+nuQGNBGHzHEEBDADXmFncoc9Q/2JSf4jgNLnsJwWE
wfum46tSntdLM78dyhgqy6mCHyFBrWsT5QkDctVHBccevLbhrH3AnG1Wd+G5wqNJ
JnB9WpsZWwthMPCljZ9tmJ0BUqcMQMUDXpHy2vkzQ26NDlr/VmHpa2SJCT6Felwe
7Z+nYCtoiq+39x7tSzSmMSZ1usfOe3xaoNXrBZVE+ZF/UI4SjXMrlXbrQrrDu/pH
6FOjvaItTESxoOVcvzQIPyX1iBpneH1NzpCxDxliJVBzL0IU4+gcawSD/3cY5J8r
mRb2udzVN7KYxSwxbYpbh8RkuvC8qmDYFyNWRv9KPEscyL4qsJQhmB4Q95b3Sn0N
Vsc+ze78JzQrJVCtpy433I0HZdrrth87Xrx9ewrFGV+bkzzvUiuHZpw6oISTRZTl
bh0/Oaej9xwupdK6C3xxXS47P3Z3VSKJmt/Bu6aA/Dgs8Ef04hqwg9Gk2DdUXncq
wJlWcGDnfiV2x5jfBB19hTeth60PIkNDHrJEWQsAEQEAAYkBtgQYAQgAIBYhBCdD
ne42Cd8ePRn1RRXfNhAaEbc2BQJh8xxBAhsMAAoJEBXfNhAaEbc2G3gL/iAjIfxq
Y2r0P+6z5VSGpDu7pztkHRRcTT9ZA00r1g4M1RVbE6ZC4iyMrp3PZTPd5ZnCdwCX
oB5jQXi439rofZfqrKRKjj2rpf/GQUwtf2HjcN9LS94D3H0VtBQS1vYWb+M4g8jS
kZfD9Q/nbB4zMGc9v/drWCzgJqQdm6lnhBzo4wVwhcUqH+Yfr7EzNblEEtaDYyfO
N3YcTB193IsTrIxmZ76DOlfHoGyYwtbkd9pZguToR7iEYvk00Xzw1440oFOGzwJa
AQ16lpQ/A90fYy5K/0MuKnMq23cqkgytAH2nXpyvlbRuowIsoBVRwPRQHflRZKZo
9CY8jj9DwWKMF2IN5A6FCRg+TBAzqSKMoYxTyz3F0aQIXcATzEUFHSEazoi/AUh+
fllG4qKi6A2iAHynpUyXw56NWzINOyh2/txMvNb8igqLAbXueMFjYjP/UHhWbdPI
F7P5slFDSTfSyUQpg4JcSg4+udehM0ssZ5aNkPFmqN1D7j5429yrRiVrTA==
=nVev
-----END PGP PUBLIC KEY BLOCK-----`;

export const PGP = () => (
	<div className="PGP-key-container">
		<textarea className="PGP-key" readOnly onClick={(e) => e.target.select()}>
			{PGP_PUB}
		</textarea>
	</div>
);
